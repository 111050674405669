import { ServicesData } from '../../services/services.data';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { YarzeitsData } from '../yarzeits.data';
import { Yarzeit } from './yarzeit';

@Component({
  selector: 'yarzeit-edit',
  templateUrl: './yarzeit-edit.tmpl.html'
})

export class YarzeitEdit implements OnInit {
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private yarzeitsData: YarzeitsData,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
  ) {
  }

  yarzeit: Yarzeit;
  id: number;
  contactId: number;
  yarzeitForm: FormGroup;

  public load(): void {
    if (this.id > 0) {
      this.yarzeitsData.getEdit(this.id || 0)
        .subscribe((c: any) => {
          this.yarzeit = c;
          this.yarzeitForm.patchValue(c);
        });
    } else {
      this.yarzeitsData.getAdd()
        .subscribe((c: any) => {
          this.yarzeit = c;
          this.yarzeitForm.patchValue(c);
        });
    }
  }


  private initForm() {
    this.yarzeitForm = this.formBuilder.group({
      createBy: [], createDate: [], changeBy: [], changeDate: [],
      yarzeitID: [this.id || 0, Validators.required],
      contactID: [],
      contactName: [],
      perfix: [],
      lastName: [],
      firstName: [],
      suffix: [],
      localLastName: [],
      localFirstName: [],
      deathDate: [],
      gender: [, Validators.required],
      associationToContactCard:[true, Validators.required],
      fatherName: [],
      motherName: [],
      chotenName: [],
      spouseName: [],
      hometown: [],
      originCity: [],
      burialPlace: [],
      burialDate: [],
      comment: []
    });
  }


  ngOnInit() {
    this.activatedRoute.params.forEach((params: Params) => {
      this.id = +params['yarzeitID'];
    });
    this.initForm();
    this.load();
  }
}



