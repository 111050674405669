﻿<form [formGroup]="letterSettingForm" novalidate *ngIf="letterSetting">
  <div class="modal-header">
    <h1>עריכת פרטי מכתב [{{ (id > 0 ? id : 'חדש') }}] </h1>
  </div>
  <div class="modal-body">
    <div class="example-form">
      <div class="col-sm-12">
        <fieldset class="form-group" fxLayout="column">
          <legend>פרטי מכתב</legend>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div style="width: 250px !important;" class="input-group mb-3">
              <label>שם המכתב:</label>
              <input type="text" class="form-control" formControlName="letterName" />
            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>חשבון מכתב (ב"מ):</label>
              <mat-select apply-enter class="form-control" placeholder="משוייך לחשבון..."
                [formControl]="letterSettingForm.controls.accountLocation">
                <mat-option *ngFor="let cs of servicesData.AccountLocations" [value]="cs.value">
                  {{cs.name}}</mat-option>
              </mat-select>

            </div> &nbsp;

            <div class="form-row" fxLayout="row" fxLayout.xs="column">
              <div class="input-group mb-3">
                <label>משוייך לנושאים:</label>
                <mat-select apply-enter class="form-control" (selectionChange)="subjectsSelectionChange($event)"
                  placeholder="משוייך לנושאים..." [formControl]="letterSettingForm.controls.commitmentSubjectsList" multiple>
                  <mat-option *ngFor="let cs of servicesData.CommitmentSubjects" [value]="cs.value">
                    {{cs.name}}</mat-option>
                </mat-select>
              </div>
            </div>

            <!-- <div class="input-group mb-3">
              <label>נושא:</label>
              <select apply-enter class="form-control" style="width: 100% !important;"
                formControlName="commitmentSubject">
                <option [value]="">...</option>
                <option *ngFor="let cs of servicesData.CommitmentSubjects" [value]="cs.value">
                  {{cs.name}}
                </option>
              </select>
            </div> -->
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>משוייך לנושאים מכתב (ב"מ):</label>
              <mat-select apply-enter class="form-control" (selectionChange)="reasonSelectionChange($event)"
                placeholder="משוייך לנושאים..." [formControl]="letterSettingForm.controls.commitmentReasonIdsList"
                multiple>
                <mat-option *ngFor="let cs of commitmentReasons" [value]="cs.commitmentReasonID">
                  {{cs.name}}</mat-option>
              </mat-select>

            </div>
          </div>



          <div  class="form-row" fxLayout="row" fxLayout.xs="column">
            <div style="width: 100%" class="input-group mb-3">
              <label>תבנית כותרת וכתובת:</label>
              <textarea style="direction: ltr; width: 100%;" rows="5" formControlName="titleAndShippingAddressTemplate"></textarea>
              <!-- <app-ngx-editor   [spellcheck]="true" formControlName="titleAndShippingAddressTemplate"></app-ngx-editor> -->

            </div>
          </div>
          <div  class="form-row" fxLayout="row" fxLayout.xs="column">
            <div style="width: 100%"  class="input-group mb-3">
              <label>תבנית גוף מכתב:</label>
              <textarea style="direction: ltr; width: 100%;" rows="5" formControlName="mainTemplate"></textarea>
              <!-- <app-ngx-editor   [spellcheck]="true" formControlName="mainTemplate"></app-ngx-editor> -->

            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div style="width: 100%" class="input-group mb-3">
              <label>תבנית פרטי תשלום בקבלה:</label>
              <textarea style="direction: ltr; width: 100%;" rows="5" formControlName="paymentTemplate"></textarea>
              <!-- <app-ngx-editor   [spellcheck]="true" formControlName="paymentTemplate"></app-ngx-editor> -->

            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>שפה:</label>
              <select class="form-control" formControlName="language">
                <option value="">...</option>
                <option *ngFor="let language of servicesData.Languages" [value]="language.value">
                  {{language.name}}
                </option>
              </select>
            </div>
            &nbsp; <div class="input-group mb-3">
              <label style="white-space: nowrap;">אחוזי הגדלה לחתימה:</label>
              <input type="text" class="form-control narrow" formControlName="widthPercentage" />
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label>גופן (TTF):</label>
              <input type="text" class="form-control" formControlName="fontName" />
            </div>
            &nbsp;
            <div class="input-group mb-3">
              <label>פורמט תאריך:</label>
              <input type="text" class="form-control" formControlName="letterDateFormat" />
            </div>
          </div>


          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3 form-row">
              <label>שוליים: (L)</label>
              <input type="number" class="form-control narrow" formControlName="marginL" />
              <!-- <small *ngIf="!letterSettingForm.controls.marginL.valid" class="text-danger">*</small> -->
            </div>
            &nbsp;
            <div class="input-group mb-3">
              <label>(R)</label>
              <input type="number" class="form-control narrow" formControlName="marginR" />
              <!-- <small *ngIf="!letterSettingForm.controls.marginR.valid" class="text-danger">*</small> -->
            </div>
            &nbsp;
            <div class="input-group mb-3">
              <label>(T)</label>
              <input type="number" class="form-control narrow" formControlName="marginT" />
              <!-- <small *ngIf="!letterSettingForm.controls.marginT.valid" class="text-danger">*</small> -->
            </div>
            &nbsp;
            <div class="input-group mb-3">
              <label>(B)</label>
              <input type="number" class="form-control narrow" formControlName="marginB" />
              <!-- <small *ngIf="!letterSettingForm.controls.marginB.valid" class="text-danger">*</small> -->
            </div>
          </div>

          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label style="white-space: nowrap;">מימין לשמאל:</label>
              <input type="checkbox" formControlName="rtl" />
            </div>
          </div>
          <div class="form-row" fxLayout="row" fxLayout.xs="column">
            <div class="input-group mb-3">
              <label style="white-space: nowrap;">שמור כתבנית קבלה::</label>
              <input type="checkbox" formControlName="saveAsReceiptTemplate" />
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>
  <footer-edit-buttons [onSaveGoToBack]="true" [dataProvider]="letterSettingsData" [router]="router" [id]="id"
    [disabledDeleteButton]="id <= 0" [modelFormGroup]="letterSettingForm"></footer-edit-buttons>

</form>
