import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServicesData } from '../../../services/services.data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ContactsData } from '../../contacts.data';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';
import { de, tr } from 'date-fns/locale';
import { cwd } from 'process';


@Component({
  templateUrl: './gite-zmanim-dlg.tmpl.html'
})
export class GiteZmanimDlg implements OnInit {
  public formData: FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private contactsData: ContactsData,
    public servicesData: ServicesData,
    private cdRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<GiteZmanimDlg>,
  ) { }

  @ViewChild('bankBranchesCmp') bankBranchesCmp: AutoCompleteComponent;
  modelData: any;
  paymentsPlanTypeName: string = '';
  ngOnInit(): void {
    this.loadData();
  }
  loadData() {
    if (this.data) {
      this.contactsData.getCustomProjectData(this.data.contactID, this.data.projectName)
        .subscribe((c: any) => {
          this.modelData = c;
          this.initForm();
        });
    }
  }
  selectAmountType(e) {
    this.formData.controls['amount'].setValue(e.source.value);
  }


  selectType(e) {
    if (e) {
      switch (e.source.value) {
        case 1:
          this.paymentsPlanTypeName = 'לחודש';
          break;
        case 2:
          this.paymentsPlanTypeName = 'אחת לשנה';
          break;
        case 3:
          this.paymentsPlanTypeName = 'אחת לשבוע';
          break;
        case 4:
          this.paymentsPlanTypeName = 'לפעימה';
          break;
        default:
          this.paymentsPlanTypeName = '';
          break;
      }
    }
  }


  activeTabIndex = 0;
  payOption = null;
  private initForm(): void {
    this.formData = this.formBuilder.group({
      //    giteZmanimKlitas:[this.modelData?.giteZmanimKlitas || 0,  Validators.required],
      contactID: [this.modelData?.contactID], //, Validators.required
      commitmentReasonID: [this.modelData?.commitmentReasonID, Validators.required],
      amount: [this.modelData?.amount, Validators.required],  //סכום
      currency: [this.modelData?.currency || 3, Validators.required], //מטבע
      qty: [this.modelData?.qty], //תשלומים
      paymentOption: [],
      payMethodID: [],
      comment: [],
      //פרטי אשראי
      creditNumber: [],
      expiryStr: [],
      paymentsPlanType: [4, this.typesVlidators],
      cvv: [],
      //פרטי הו"ק בנקאי
      bankAcountNumber: [],
      bankBranchNumber: [],
      bankBranchName: [],
      bankNumber: [],
      bankName: [],
      bankAcountOwner: [, this.hoksVlidators],
      amountLimit: [],
      dateLimit: [],

      commitmentOnly: [false, Validators.required],

    });
    if (!this.modelData.recognizedPayMethods) {
      this.activeTabIndex = 1;
      this.payOption = 1;
      this.formData.controls['paymentOption'].setValue(this.payOption);
    } else {
      this.activeTabIndex = 0;
      this.payOption = null;
      this.formData.controls['paymentOption'].setValue(this.payOption);
    }

    this.selectType({ source: { value: this.formData.controls.paymentsPlanType.value } });

  }

  public itemSelect(id: number) {
    this.bankBranchesCmp.sourceList = `BankBranches/${id}`;
  }
  slideChange(e) {
    // console.log(e);
  }
  tabItemSelect(e) {

    let ix = +e.index;
    switch (ix) {
      case 1:
        this.payOption = 1;
        this.formData.controls['paymentOption'].setValue(1);
        break;
      case 2:
        this.payOption = 4;
        this.formData.controls['paymentOption'].setValue(4);
        break;
      default:
        this.payOption = null;
        this.formData.controls['paymentOption'].setValue(null);
        break;
    }
    //יש להתייחס לאמצעי תשלום  כדלהלן
    // ריק = אמצעי קיים
    //1 = כרטיס אשראי
    //4 = הוראת קבע

  }
  pmSelectionChanged(e) {
    this.formData.controls['payMethodID'].setValue(e.payMethodID);
  }

  creditCardVlidators = c => {
    if (!c.value && this.payOption == 1) {
      return { required: true };
    }
    return null;
  };

  oldPmVlidators = c => {
    if (!c.value && this.payOption == null) {
      return { required: true };
    }
    return null;
  };

  hoksVlidators = c => {
    if (!c.value && this.payOption == 4) {
      return { required: true };
    }
    return null;
  };

  typesVlidators = c => {
    if (!c.value && this.formData.controls.commitmentOnly.value == false) {
      return { required: true };
    }
    return null;
  };

  public isValid(): boolean {
    let ret = false;

    if (this.formData.controls.commitmentOnly.value == false) {

      switch (this.payOption) {
        case null:
          ret = this.formData.controls['payMethodID'].value != null;
          break;
        case 1:
          ret = this.formData.controls['creditNumber'].value != null
            && this.formData.controls['expiryStr'].value != null;
          break;
        case 4:
          ret = this.formData.controls['bankNumber'].value != null
            && this.formData.controls['bankBranchNumber'].value != null
            && this.formData.controls['bankAcountNumber'].value != null;
          break;
        default:
          break;
      }
    } else {
      ret = true;
    }
    return ret;
  }

  loading = false;

  saveGiteZmanim() {
    console.log(this.formData);
    this.loading = true;
    if (this.isValid()) {


      this.contactsData.updateGzData(this.formData.value)
        .subscribe((x: any) => {

          this.dialogRef.close();
        }, error => {
          this.loading = false;
        })

    } else {

      this.loading = false;
      alert('אנא מלא את כל השדות המסומנים בכוכבית *');
    }

  }
}
