﻿<div style="height: 100%;" loading-status [stopLoadingWhen]="contact != null">
  <div *ngIf="contact" fxLayoutAlign="start stretch" fxLayout="column" fxFlex="100">
    <span style="position: absolute; top:0; left:12px; background-color: white;">[{{ contact.contactID }}]</span>
    <div fxLayout="row" *ngIf="!rerender">
      <div class="col-lg-5">
        <div>
          <div class="row">
            <div [ngStyle]="{'color':(contact.affiliationName == 'אינו אנש' ? 'DARKGRAY' : '')}">
              <h1 #hebTitle
                style="width: fit-content;width: fit-content; margin-top: 0px; BACKGROUND: WHITE; PADDING: 10px; border-bottom: solid; border-color: lightgray;">
                <mat-icon matSuffix matTooltip="העתק שם וקוד"
                  [cdkCopyToClipboard]="(contact.contactTitle + ' - ' + contact.codeIchud)"
                  style="color: gray; font-size: 75%; margin: 0 auto;">content_copy</mat-icon>
                {{ contact.contactTitle }}
                <span
                  style="padding: 4px;margin:20px; white-space: nowrap; padding: 4px 6px; border: solid; border-width: 1px; font-weight: bold; font-size: 68%;width: fit-content; background: WHITE;">
                  קוד איחוד: {{ contact.codeIchud }}
                </span>
                <mat-icon matSuffix matTooltip="העתק קישור" [cdkCopyToClipboard]="url+location.path()"
                  style="color: blue; font-size: 75%;">link</mat-icon>
              </h1>
              <h3 *ngIf="contact.localFirstName || contact.localLastName"
                [style.max-width.px]="(hebTitle.offsetWidth + 20)"
                style="direction: ltr;padding-top:10px; border-top: 0.5px solid;">
                {{contact.localPerfix}} {{contact.localFirstName}}
                {{contact.localLastName}}{{contact.localSuffix}}
              </h3>

            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6" style="text-align:end; white-space: nowrap;">
        <div class="row">
          <div>

            <a *ngIf="!gZInfo || (gZInfo && !gZInfo.newPaymentsPlanID && !gZInfo.newCommitmentID && (gZInfo.countPayments  == null || gZInfo.countPayments == 0))"
            style="background-color: blue;" class="btn btn-primary" (click)="openGiteZmanimDlg()">
            גוטע זמנים - קליטה</a>

          <a *ngIf="gZInfo && gZInfo.newPaymentsPlanID"
            [routerLink]="[ '', {outlets: {popup: [ 'paymentsPlans', gZInfo.newPaymentsPlanID]}}]">
            עדכון <u>תוכנית</u> גוטע זמנים</a>

          <a *ngIf="gZInfo && gZInfo.newCommitmentID && !gZInfo.newPaymentsPlanID"
            [routerLink]="[ '', {outlets: {popup: [ 'commitments', gZInfo.newCommitmentID]}}]">
            עדכון <u>התחייבות</u> גוטע זמנים</a>

          <a *ngIf="gZInfo && gZInfo.onePaymentID"
            [routerLink]="[ '', {outlets: {popup: [ 'payments', gZInfo.onePaymentID]}}]">
            תשלום גוטע זמנים</a>



                &nbsp;
            <a *ngIf="aIData && !aIData.paymentsPlanID && !aIData.commitmentID && (aIData.countPayments  == null || aIData.countPayments == 0)"
              style="background-color: blue;" class="btn btn-primary" (click)="openAchasIledorosDlg()">
              אחת ולדורות - קליטה</a>

            <a *ngIf="aIData && aIData.paymentsPlanID"
              [routerLink]="[ '', {outlets: {popup: [ 'paymentsPlans', aIData.paymentsPlanID]}}]">
              עדכון <u>תוכנית</u> אחת ולדורות</a>

            <a *ngIf="aIData && aIData.commitmentID && !aIData.paymentsPlanID"
              [routerLink]="[ '', {outlets: {popup: [ 'commitments', aIData.commitmentID]}}]">
              עדכון <u>התחייבות</u> אחת ולדורות</a>

            <a *ngIf="aIData && aIData.onePaymentID"
              [routerLink]="[ '', {outlets: {popup: [ 'payments', aIData.onePaymentID]}}]">
              תשלום אחת ולדורות</a>

            &nbsp;&nbsp;&nbsp;&nbsp;
            <div class="btn-group">
              <button *authGuardDirective="'commitments,payments'" data-toggle="dropdown"
                class="btn btn-success dropdown-toggle">קליטת תרומות והתחייבוית&nbsp;&nbsp;
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li *authGuardDirective="'payments'">
                  <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'payments', 'add']}}]">תרומה (תשלום)</a>
                </li>
                <li *authGuardDirective="'commitments'">
                  <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'commitments', 'add']}}]">התחייבות
                    חדשה</a>
                </li>
                <li *authGuardDirective="'paymentsPlans'">
                  <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'paymentsPlans', 'add']}}]">תוכנית
                    תשלום</a>
                </li>
              </ul>

            </div>
            <div class="btn-group">
              <button *authGuardDirective="'processes,honors,registrations_add'" data-toggle="dropdown"
                class="btn btn-primary dropdown-toggle">הוספה&nbsp;&nbsp;
                <span class="caret"></span>
              </button>
              <ul *authGuardDirective="'processes,honors,registrations_add'" class="dropdown-menu">

                <li *authGuardDirective="'tishInviteds'">
                  <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'tishInviteds', 'addInvited', 30]}}]">הוסף
                    זימון</a>
                </li>
                <li *authGuardDirective="'honors'">
                  <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'honors', 'add']}}]">הוסף כיבוד</a>
                </li>
                <li *authGuardDirective="'registrations_add'">
                  <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'registrations', 'add']}}]">הוסף רישום
                    לאירוע</a>
                </li>
                <li *authGuardDirective="'processes'">
                  <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'processes', 'add']}}]">פתיחת תהליך</a>
                </li>
                <li *authGuardDirective="'payMethods'">
                  <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'payMethods', 'add', 1]}}]">קליטת אשראי
                    חדש
                  </a>
                </li>
                <li *authGuardDirective="'payMethods'">
                  <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'payMethods', 'add', 4]}}]">קליטת
                    הו"ק בנקאי חדשה</a>
                </li>


              </ul>
            </div>
            <div class="btn-group">
              <button data-toggle="dropdown" *authGuardDirective="'commitments,reports'"
                class="btn btn-success dropdown-toggle">נתונים ודוחו"ת&nbsp;&nbsp;
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a (click)="openHoldingsExlsDlg()">דו"ח החזקת ביהמ"ד</a>
                </li>
                <li>
                  <a (click)="viewShortInfoByYear()">סיכום לפי שנה</a>
                </li>
                <li>
                  <a (click)="getContactShortInformation()">דו"ח כללי</a>
                </li>
              </ul>

            </div>
            <div class="btn-group">
              <button *authGuardDirective="'payments,contacts,commitments,receipts'" data-toggle="dropdown"
                class="btn btn-primary dropdown-toggle">קבלות והדפסות&nbsp;&nbsp;
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li *authGuardDirective="'receipts'">
                  <a (click)="printNewReceiptsForContact()">הדפס קבלות שלא הודפסו</a>
                </li>
                <li *authGuardDirective="'payments,receipts'">
                  <a (click)="openReciptsForContactDlg()">צור והדפס קבלות מרוכזת</a>
                </li>
                <li *authGuardDirective="'receipts'">
                  <a (click)="createAndrintNewReceiptsForContact()">צור והדפס קבלות שלא הודפסו</a>
                </li>

                <li *authGuardDirective="'contacts'">
                  <a (click)="printEnvelopeorContact()">הדפס מעטפה</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row modal-body" *ngIf="!rerender">

      <mat-tab-group [selectedIndex]="activeTabIndex" (selectedTabChange)="itemSelect($event)">
        <mat-tab #tab_0 label="0" *authGuardDirective="'Contacts'">
          <ng-template mat-tab-label>
            מבט
          </ng-template>
          <div *ngIf="tab_0.isActive" class="row">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-md-6">
                  <mat-card class="example-card" style="width:100%;" fxLayoutAlign="start stretch" fxLayout="column">
                    <mat-card-header>
                      <mat-card-title fxLayout="row">
                        <h3>פרטי איש: [{{ id }}]</h3>
                        <span class="example-fill-remaining-space"></span>
                        <a [routerLink]="['', {outlets: {popup: ['contacts', id, 'edit']}}]">
                          <i class="fa fa-pencil"></i>
                        </a>
                        <model-info *ngIf="modelInfo" [modelInfo]="modelInfo"></model-info>
                      </mat-card-title>
                    </mat-card-header>
                    <mat-card-content style="width: 100%;">
                      <contact-short-details [contact]="contact"></contact-short-details>
                    </mat-card-content>
                  </mat-card>

                </div>
                <div class="col-md-6">
                  <div fxLayoutAlign="start stretch" fxLayout="column" fxFlex="100">
                    <addresses-list [contactID]="id"></addresses-list>
                    &nbsp;&nbsp;
                    <phones-list [contactID]="id"></phones-list>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="col-md-6">
                <div fxLayoutAlign="start stretch" fxLayout="column" fxFlex="100">
                  <contactGroups-list *authGuardDirective="'contactGroups'" [contactID]="id">
                  </contactGroups-list>
                  &nbsp;&nbsp;
                  <contactAppointments-list *authGuardDirective="'appointments'" [contactID]="id">
                  </contactAppointments-list>
                </div>
              </div>

              <div class="col-md-6">
                <div fxLayoutAlign="start stretch" fxLayout="column" fxFlex="100">
                  <contactEvents-list *authGuardDirective="'contactEvents'" [contactID]="id">
                  </contactEvents-list>
                  &nbsp;&nbsp;
                  <contactCompensations-list *authGuardDirective="'compensations'" [contactID]="id">
                  </contactCompensations-list>
                  <!-- &nbsp;&nbsp;
                      <yarzeits-list  [contactID]="id">
                      </yarzeits-list> -->
                </div>
              </div>
            </div>
            <!-- style="background-color: greenyellow; padding: 2px; height: calc(100vh - 200px);" -->





          </div>
        </mat-tab>
        <mat-tab #tab_1 label="1" *authGuardDirective="'commitments'">
          <ng-template mat-tab-label>
            התחייבויות
          </ng-template>
          <commitments-list (selectedRowsChange)="selectedCommitmentsRowsChange($event)" *ngIf="tab_1.isActive"
            [contactID]="contact.contactID">Loading...</commitments-list>
        </mat-tab>
        <mat-tab #tab_2 label="2" *authGuardDirective="'paymentsPlans'">
          <ng-template mat-tab-label>
            תוכניות תשלום
          </ng-template>
          <paymentsPlans-list *ngIf="tab_2.isActive" [contactID]="contact.contactID">Loading...
          </paymentsPlans-list>
        </mat-tab>
        <mat-tab #tab_3 label="3" *authGuardDirective="'payments'">
          <ng-template mat-tab-label>
            תשלומים
          </ng-template>
          <payments-list *ngIf="tab_3.isActive" [contactID]="contact.contactID">Loading...
          </payments-list>
        </mat-tab>
        <mat-tab #tab_4 label="4" *authGuardDirective="'registrations'" aria-label="'registrations'">
          <ng-template mat-tab-label>
            סטנציה
          </ng-template>
          <registrations-list *ngIf="tab_4.isActive" [contactID]="contact.contactID">Loading...
          </registrations-list>
        </mat-tab>
        <mat-tab #tab_5 label="5" *authGuardDirective="'payMethods'" [textLabel] [aria-label]="'payMethods'">
          <ng-template mat-tab-label>
            אמצעי תשלום
          </ng-template>
          <payMethods-list *ngIf="tab_5.isActive" [contactID]="contact.contactID">Loading...
          </payMethods-list>
        </mat-tab>
        <mat-tab #tab_6 label="6" *authGuardDirective="'honors'">
          <ng-template mat-tab-label>
            כיבודים ועליות
          </ng-template>
          <honors-list *ngIf="tab_6.isActive" [contactID]="contact.contactID">Loading...</honors-list>
        </mat-tab>
        <mat-tab #tab_7 label="7" *authGuardDirective="'receipts'">
          <ng-template mat-tab-label>
            קבלות
          </ng-template>
          <receipts-list *ngIf="tab_7.isActive" [contactID]="contact.contactID">Loading...</receipts-list>
        </mat-tab>

        <mat-tab #tab_8 label="8" *authGuardDirective="'holdings'">
          <ng-template mat-tab-label>
            החזקת בית המדרש
          </ng-template>
          <holdings-list *ngIf="tab_8.isActive" [contactID]="contact.contactID">Loading...
          </holdings-list>
        </mat-tab>

        <mat-tab #tab_9 label="9" *authGuardDirective="'tishInviteds'">
          <ng-template mat-tab-label>
            זימון לאירועים
          </ng-template>
          <tishInviteds-list *ngIf="tab_9.isActive" [contactID]="contact.contactID">Loading...
          </tishInviteds-list>
        </mat-tab>

        <mat-tab #tab_9 label="9" *authGuardDirective="'tasks'">
          <ng-template mat-tab-label>
            משימות
          </ng-template>
          <tasks-list *ngIf="tab_9.isActive" [contactID]="contact.contactID">Loading...</tasks-list>
        </mat-tab>

        <mat-tab *authGuardDirective="'contactActivities'">
          <ng-template mat-tab-label>
            משימות ישנות
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
