import { MaterialModule } from './../material.module';
import { HonorMain } from './honor/honor-main.cmp';
import { MainFormModalModule } from './../components/main-form-modal/main-form-modal.mdl';

import { HonorsRoutes } from './honors.routing';
import { AutoCompleteModule } from '../components/‏‏auto-complete/auto-complete.mdl';
import { DateInputModule } from './../components/dateInput/date-input.mdl';
import { FooterEditButtonsMdl } from './../components/footer-edit-buttons/footer-edit-buttons.mdl';
import { PaginationModule } from './../components/pagination/pagination.mdl';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeadingModule } from '../components/page-heading/page-heading.mdl';
import { PanelBoxModule } from '../components/panel-box/panel-box.mdl';
import { HonorsData } from './honors.data';
import { HonorEdit } from './honor/honor-edit.cmp';
import { HonorDetails } from './honor/honor-details.cmp';
import { HonorsList } from './honors-list.cmp';
import { AmountAndCurrencyInputModule } from '../components/amount-and-currency-input/amount-and-currency-input.module';
import { ModelInfoModule } from '../components/model-info/model-info.mdl';
import { CustomToolTipModule } from '../components/custom-tool-tip/custom-tool-tip.mdl';


@NgModule({
	imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
		PageHeadingModule,
		PanelBoxModule,
		PaginationModule,
		FooterEditButtonsMdl,
		DateInputModule,
		AutoCompleteModule,
		RouterModule.forChild(HonorsRoutes),
		MaterialModule,
		MainFormModalModule,
		AmountAndCurrencyInputModule,
    ModelInfoModule,
    CustomToolTipModule

	],
	declarations: [
		HonorMain,
		HonorsList,
		HonorEdit,
		HonorDetails,
	],
	exports: [
		HonorsList,
		HonorMain,
		HonorEdit,
		HonorDetails,
	],
	providers: [
		HonorsData
	]
})
export class HonorsMdl { }

