import { AlertService } from "./../components/alert/alert.svc";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material/dialog";
import { ServicesData } from "../services/services.data";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToraxHttp } from "../utils/custom-http.svc";
import { IndexService } from "../services/index-service";
import { LetterSettingsData } from "./letter-settings.data";
import { SubscriptionLike } from "rxjs";

@Component({
  selector: "letter-settings-list",
  templateUrl: "letter-settings-list.tmpl.html",
})
export class LetterSettingsList implements OnInit, OnDestroy {
  constructor(
    private letterSettingsData: LetterSettingsData,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public servicesData: ServicesData,
    public _matDialog: MatDialog,
    private toraxHttp: ToraxHttp,
    private alert: AlertService
  ) {
    let sub = this.toraxHttp!.updatedEvent.subscribe(i => {
      if (this.indexService && i.type.indexOf('/letter-settings') > -1) {
        this.indexService.getList();
      }
    });
    this.subscriptions.push(sub);
  }

  subscriptions: SubscriptionLike[] = [];
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  groupingColumn = '';
  groupingOrderColumn = '';
  displayedColumns: string[] = ["select", "buttons", "letterSettingID", "letterName", "commitmentSubjects", "commitmentReasonIds"];

  defaultvalues: any[];
  selectedItems;
  indexService: IndexService;
  searchLetterSettingsListForm: FormGroup;

  actualMinusHeight = '185';

  @Output() selectedRowsChange = new EventEmitter<any>();
  public selection = new SelectionModel<number>(true, []);


  isChecked(row: any): boolean {
    const found = this.selection.selected.find(
      (el: any) => el.letterSettingID === row.letterSettingID
    );
    if (found) {
      return true;
    }
    return false;
  }

  rowToggle(row) {
    this.selection.toggle(row);
    row.selected = !row.selected;
  }

  addNew() {
    this.router.navigate([{ outlets: { popup: ["add"] } }]);
  }

  openSelectedRow(letterSettingID) {
    this.router.navigate(["", { outlets: { popup: ["letterSettings", letterSettingID] } }]);
  }


  private initForm() {
    this.searchLetterSettingsListForm = this.formBuilder.group({
      page: [1],
      pageRows: [20],
      query: [],
      selectedRows: [],
      orders: this.formBuilder.array([]),
    });

    this.selection.changed.subscribe((x) => {
      this.searchLetterSettingsListForm.controls["selectedRows"].setValue(
        x.source.selected.map((x: any) => x.letterSettingID)
      );
      this.selectedRowsChange.emit(x.source.selected);
    });
  }

  ngOnInit() {
    this.initForm();

    this.activatedRoute.params.forEach((params: Params) => {
    });

    let item = sessionStorage.getItem(this.router.url);
    if (item) {
      let frm = JSON.parse(sessionStorage.getItem(this.router.url) || "");
      this.searchLetterSettingsListForm.patchValue(frm);
    }

    this.searchLetterSettingsListForm.updateValueAndValidity();
    this.selection.isSelected = this.isChecked.bind(this);
    let params = [
    ];
    this.indexService = new IndexService(this.searchLetterSettingsListForm, params, this.letterSettingsData, this.router, this.defaultvalues, '', this.groupingColumn, this.groupingOrderColumn);
    this.indexService.getList();

  }
}


// import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
// import { Component, OnInit } from '@angular/core';
// import { LetterSettingsData } from './letter-settings.data';
// import { ObjectsListResult } from '../services/objects-list-results';
// import { Router } from '@angular/router';

// @Component({

//     selector: 'letter-settings-list',
//     templateUrl: './letter-settings-list.tmpl.html'
// })

// export class LetterSettingsList implements OnInit {
//     constructor(
//         private letterSettingsData: LetterSettingsData,
//         private formBuilder: FormBuilder,
//         private router: Router
//     ) {
//         // this.toraxHttp.updatedEvent.subscribe((id) => {
//         // 	this.getLetterSettings();
//         // });
//     }
//     public objectsListResult: ObjectsListResult;
//     searchLetterSettingsListForm: FormGroup;

//     public getLetterSettings(): void {
//         this.letterSettingsData.getList(this.searchLetterSettingsListForm.value).subscribe(
//             (r:any) => {
//                 this.objectsListResult = r;
//             },
//             error => { });
//     }

//     public changePage(page: number) {
//         this.searchLetterSettingsListForm.controls['page'].setValue(page);
//         this.search();
//     }

//     public clerFilter() {
//         this.initForm();
//         this.search();
//     }

//     public search() {
//         sessionStorage.setItem(this.router.url, JSON.stringify(this.searchLetterSettingsListForm.value));
//         this.getLetterSettings();
//     }

//     private initForm() {
//         this.searchLetterSettingsListForm = this.formBuilder.group({
//             page: [1],
//             pageRows: [20],
//             query: [],
//             selectedRows:[],
// 			orders: this.formBuilder.array([])
//         });
//     }
//     ngOnInit(): void {
//         this.initForm();
//         this.getLetterSettings();
//     }

//     addOrder(propertyName: string, desc = false) {
//         const ordersControl = <FormArray>this.searchLetterSettingsListForm.controls['orders'];

//         let ord = this.getOrder(propertyName);
//         if (ord != null) {
//             ord.controls['value'].setValue(desc);
//         } else {
//             ord = this.initOrder(propertyName, desc);
//             ordersControl.push(ord);
//         }
//         this.search();
//     }

//     initOrder(propertyName: string, desc = false) {
//         let propName = propertyName;
//         let des = desc;
//         return this.formBuilder.group({
//             key: [propName],
//             value: [des],
//         });
//     }

//     getOrder(columnName: string): any {
//         let lst = <FormArray>this.searchLetterSettingsListForm.controls['orders'];
//         // tslint:disable-next-line:curly
//         if (lst == null) return null;

//         for (let i = 0; i < lst.length; i++) {
//             if ((<FormGroup>lst.controls[i]).controls['key'].value === columnName) {
//                 return lst.controls[i];
//             }
//         }
//         return null;
//     }

//     orderType(columnName: string) {
//         let order = this.getOrder(columnName);
//         return order != null ? order.controls['value'].value : null;
//     }

// }
