import { MatDialog } from '@angular/material/dialog';
import { AddressesData } from '../addresses.data';
import { Component, Input, EventEmitter, Output } from '@angular/core';

import { Address } from './address';
import { AlertService } from 'src/app/components/alert/alert.svc';

@Component({
    selector: 'address-details',
    templateUrl: './address-details.tmpl.html'
})

export class AddressDetails {

    constructor(
        public dialog: MatDialog,
        private addressesData: AddressesData,
        private alert: AlertService,
    ) {

    }
    @Output() addressChange = new EventEmitter<any>();
    @Input('address') public address: Address;

    addressChangeEvent(address) {
        this.addressChange.emit(address);
    }
    updateGoogleAddress(addressID){
      this.addressesData.updateGoogleAddress(addressID)
      .subscribe((adr: any) => {
          //this.address = adr;
          this.alert.info('המתן בבקשה, מעדכן ופותח חלון מפות גוגל');
          let link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.href = `http://www.google.com/maps/place/${this.address.latitude},${this.address.longitude}`;
          link.click();
      });
    }
}



