<form class="example-form" [formGroup]="formData" novalidate>
  <div matDialogTitle class="modal-header">
    <h2>
      <button style="float: left" mat-dialog-close aria-label="Close dialog" title="יציאה"
        class="btn  btn-circle.btn-lg">
        <i class="fa fa-times"></i>
      </button>
      <small>דו"ח הדפסות החזקת ביהמ"ד</small>
    </h2>
  </div>
  <mat-dialog-content *ngIf="formData" style="padding:0px 24px 24px 24px">
    <div fxLayout="column" style="display: block;">

        <auto-complete style="background-color: white;" apply-enter #cityID [placeholder]="'עיר...'"
        [controllerName]="'Cities'" [formGroupModel]="formData" [idControl]="formData.controls.cityID"
        [nameControl]="formData.controls.cityName">
      </auto-complete>



      <auto-complete apply-enter placeholder="שטיבל..." #shtibelID [controllerName]="'Shtibels'"
        [formGroupModel]="formData" [idControl]="formData.controls.shtibelID"
        [nameControl]="formData.controls.shtibelName"></auto-complete>

    </div>
    <div class="input-group mb-3" style="white-space: nowrap;">
      <label>כלול היסטוריה:</label>
      <mat-checkbox fxLayout="row" class="example-margin" formControlName="includeHistory">
      </mat-checkbox>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions class="modal-footer" align="end">
     <button apply-enter [disabled]="formData.invalid" class="btn btn-default navbar-btn" (click)="exportExcel()"><i
        class="fa fa-save"></i> אקסל</button>
    <button apply-enter [disabled]="formData.invalid" class="btn btn-primary navbar-btn" (click)="exportPdf()"><i
        class="fa fa-save"></i> PDF </button>
  </mat-dialog-actions>

</form>
