<div style="cursor: initial" *ngIf="phone && phone.contact" [ngStyle]="{'color': (phone.isActive) ? 'inherit' : 'red'}">
  <div class="example-full-width" fxLayout="row" fxLayoutAlign="start center">
    <a fxLayout="column" style="padding: 4px" (click)="editPhone(phone)">
      <i class="fa fa-pencil"></i>
    </a> &nbsp;
    <model-info fxLayout="column" style="padding: 4px" [modelInfo]="phone"></model-info>
    <div fxLayout="column" style="padding: 4px">
      <i *ngIf="phone.type == 1" class="fa fa-home" style="min-width: 35px;"><br><label class="label inline" style="padding: 2px;">בית</label></i>
      <i *ngIf="phone.type == 2" class="fa fa-mobile" style="min-width: 35px;"><br><label class="label inline" style="padding: 2px;">נייד</label></i>
      <i *ngIf="phone.type == 3" class="fa fa-phone" style="min-width: 35px;"><br><label class="label inline" style="padding: 2px;">נייד גב'</label></i>
      <i *ngIf="phone.type == 4" class="fa fa-phone-square" style="min-width: 35px;"><br><label class="label inline" style="padding: 2px;">נייד נוסף</label></i>
      <i *ngIf="phone.type == 5" class="fa fa-fax" style="min-width: 35px;"><br><label class="label inline" style="padding: 2px;">פקס</label></i>
      <i *ngIf="phone.type == 6" class="fa fa-briefcase" style="min-width: 35px;"><br><label class="label inline" style="padding: 2px;">עבודה</label></i>
      <i *ngIf="phone.type == 7" class="fa fa-automobile" style="min-width: 35px;"><br><label class="label inline" style="padding: 2px;">רכב</label></i>


      <i *ngIf="phone.type == 11" class="fa fa-envelope-o" style="min-width: 35px;"><br><label class="label inline" style="padding: 2px;">דוא"ל'</label></i>
    </div>
    <div style="padding-right:7px" fxLayout="column" fxFlex>
      <div fxLayout="row">
        <div *ngIf="phone.type <= 10" style="direction: ltr">{{ phone.number | phone }}</div>
        <div *ngIf="phone.type >= 11"><a [href]="'mailto:' + phone.number" target="_top">{{ phone.number }}</a>
        </div>
      </div>
      <div fxLayout="row"><small style="background-color: #ffffe0;">{{ phone.comment }}</small></div>
    </div>

  </div>
</div>
