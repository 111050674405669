<mat-card fxLayout="column" class="example-card">
    <mat-card-header fxLayout="row">
        <mat-card-title fxLayout="row">
            <h3 style="TEXT-DECORATION: UNDERLINE;">כתובות</h3>
            <span class="example-fill-remaining-space"></span>
            <address-add-action fxLayout="column" [contactID]="contactID" (addressChange)="addressChangeEvent()">
            </address-add-action>


            <button fxLayout="column" (click)="loadAll()"
                style="min-width: 10px  !IMPORTANT; min-height: 10px  !IMPORTANT;  line-height: 10px; padding:4px"
                matTooltip="הצג גם הסטוריה" mat-stroked-button>
                <mat-icon>history</mat-icon>
            </button>
        </mat-card-title>
    </mat-card-header>


    <mat-card-content style="width: 100%;">


        <div [formGroup]="searchAddressesListForm" class="example-full-width">
            <div cdkDropList class="example-list" *ngIf="addresses" (cdkDropListDropped)="drop($event)"
                class="example-full-width">
                <div fxLayoutAlign="start center" cdkDrag class="example-box" *ngFor="let p of addresses"
                    [cdkDragData]="p">
                    <mat-icon matTooltip="גרור כדי לתעדף" class="example-handle" cdkDragHandle fxLayout="column">
                        unfold_more
                    </mat-icon>
                    <address-details fxLayout="column" fxFlex (addressChange)="addressChangeEvent(p)" [address]="p">
                    </address-details>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
