import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactsPlansAndPaymentsReportsDlg } from './rc-dialogs/rc-contacts-payments-plans.cmp';
import { PaymentsPlansReportsDlg } from './rc-dialogs/rc-payments-plans.cmp';
import { PaymentsTreatReportsDlg } from './rc-dialogs/rc-payments-treat.cmp';
import { ReportsCenterData } from './reports-center.data';
import { HoldingsPrintReportsDlg } from './rc-dialogs/rc-holdings-print.cmp';

@Component({
  selector: 'app-reports-center',
  templateUrl: './reports-center.component.html',
  styleUrls: ['./reports-center.component.scss']
})
export class ReportsCenterComponent {

  constructor(
    public dialog: MatDialog,
    private reportsCenterData: ReportsCenterData) { }


  pplDlg() {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ContactsPlansAndPaymentsReportsDlg, {
        // data: tp
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          alert(result);
        }
      });
    }, 200);
  }

  paymentsFaildDlg() {
    setTimeout(() => {
      const dialogRef = this.dialog.open(PaymentsTreatReportsDlg, {
        // data: tp
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          alert(result);
        }
      });
    }, 200);

  }

  viwShtibelsList() {
    setTimeout(() => {
      const dialogRef = this.dialog.open(HoldingsPrintReportsDlg, {
        // data: tp
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.valid) {
          alert(result);
        }
      });
    }, 200);
  }

  public shtibelsGabimVenetzigiExcel(): void {

    // this.reportsCenterData.shtibelsGabimVenetzigiExcel()
    //   .subscribe(blob => {
    //     let link = document.createElement('a');
    //     link.href = window.URL.createObjectURL(blob);
    //     link.download = `דוח תשלומים שחזרו לטיפול.xlsx`;
    //     link.click();
    //     this.dialogRef.close();
    //   });
  }
}
