import { startWith, map } from 'rxjs/operators';
import { Observable, observable } from 'rxjs';
import { AutoCompleteComponent } from 'src/app/components/‏‏auto-complete/auto-complete.cmp';
import { ServicesData } from './../../services/services.data';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddressesData } from '../addresses.data';
import { Component, OnInit, Input, Inject, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';

import { Address } from './address';
import { AutoCompleteInputComponent } from 'src/app/components/auto-complete-input/auto-complete-input.cmp';

@Component({
    selector: 'address-edit-dlg',
    templateUrl: './address-edit-dlg.tmpl.html',
    styleUrls: ['./address.cmp.scss']

})

export class AddressEditDlg implements OnInit, AfterViewInit {


    constructor(
        public dialogRef: MatDialogRef<AddressEditDlg>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private addressesData: AddressesData,
        private formBuilder: FormBuilder,
        public servicesData: ServicesData
    ) {

    }

    @Output() addressChange = new EventEmitter<any>();
    @Input('id') public id: number;
    @ViewChild('cityCmp') cityCmp: AutoCompleteComponent;
    @ViewChild('streetInput') streetInput: AutoCompleteInputComponent;


    //filterStreets: Observable<any[]> = new Observable<any[]>();
    communicationType;
    address: Address;
    contactId: number;
    streetAutofilled;
    addressForm: FormGroup;
    // autofilled(e){
    //     console.log(e);
    // }
    selectedValue: string;
    // inputText(e){
    //     //this.selectedValue = this.filterStreets[0] ? this.filterStreets[0].street : "";
    //     console.log(this.selectedValue);
    // }
    public cityIDItemSelect(cityID: number) {
        this.streetInput.sourceList = `Streets/${cityID}`;
    }
    public countryIDItemSelect(countryID: number) {
        this.cityCmp.sourceList = `Cities/${countryID}`;
    }
    updateGoogleAddress(addressID){
      this.addressesData.updateGoogleAddress(addressID)
      .subscribe((adr: any) => {
          this.address = adr;
          this.fillForm(adr);
      });
    }

    // setting this is the key to initial select.
    public load(): void {
        if (this.id > 0) {
            this.addressesData.getEdit(this.id || 0)
                .subscribe((p: any) => {
                    this.address = p;
                    this.fillForm(p);
                });
        } else if (this.address) {
            this.addressesData.getAdd(this.address.contactID)
                .subscribe((p: any) => {
                    this.address = p;
                    this.fillForm(this.address);
                });
        }
    }

    private fillForm(p: Address): void {
        this.addressForm.patchValue(p);
        this.cityCmp.sourceList = `Cities/${this.addressForm.controls.countryID.value}`;

    }


    setInactiveDate() {
        this.addressForm.controls['inactiveDate'].setValue(new Date());
        //this.addressForm.controls['isActive'].setValue(false);
        this.addressChange.emit(this.address);
    }

    clearInactiveDate() {
        this.address.inactiveDate = null;
        //this.address.isActive = true;
        this.addressForm.controls['inactiveDate'].setValue(this.address.inactiveDate);
        //this.addressForm.controls['isActive'].setValue(this.address.isActive);
        this.addressChange.emit(this.address);
    }

    // updateInactiveDate(e:boolean) {
    //     if (e) {
    //         this.addressForm.controls['inactiveDate'].setValue(null);
    //     } else {
    //         this.addressForm.controls['inactiveDate'].setValue(new Date());

    //     }
    // }
    private initForm() {
        this.addressForm = this.formBuilder.group({
            createBy: [], createDate: [], changeBy: [], changeDate: [],
            addressID: [this.id, Validators.required],
            contactID: [this.contactId, Validators.required],
            street: [, Validators.required],
            houseNum: [],
            aptNum: [],
            floor: [],
            entry: [],
            zip: [],
            pob: [],
            forPost: [],
          //  addressTitle: [],
            cityID: [],
            cityTitle: [],
            stateID: [],
            stateName: [],
            countryID: [],
            countryName: [],
            addressType: [],
            addressTypeName: [],
            confirmed: [false, Validators.required],
            inactiveDate: [],
            locationType: [],
            priority: [1, Validators.required],
            isActive: [true, Validators.required],
            comment: [],
            longitude: [],
            latitude: [],
            recipientName: [],
            rowSource:[],
            synizedAddress:[false,Validators.required],
            googleAddressTitle:[]
        });
        //this.addressForm.controls['isActive'].valueChanges.subscribe(x => this.updateInactiveDate(x));

    }

    ngOnInit(): void {
        this.id = this.data.address.addressID;
        this.contactId = this.data.address.contactID;
        this.address = this.data.address;
        this.initForm();
        this.load();
     }

    ngAfterViewInit() {
        this.addressForm.controls['cityID'].valueChanges.subscribe(x => {
            this.streetInput.sourceList = `Streets/${x}`;
        });
        this.addressForm.controls['countryID'].valueChanges.subscribe(x => {
            this.cityCmp.sourceList = `Cities/${x}`;
        });
    }

}



